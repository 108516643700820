.damcontent-leftsidebar .radio-wrapper {
  width: 100%;
  float: left;
}
.damcontent-leftsidebar
  .radio-wrapper
  .Platform-x-FormControl-root
  .Platform-x-FormControlLabel-root {
  min-width: 160px;
  clear: both;
  margin-bottom: 8px;
  margin-left: -6px;
}
.damcontent-leftsidebar .radio-wrapper .Platform-x-FormGroup-root {
  width: 100%;
  display: flex;
  justify-content: left;
}
.damcontent-leftsidebar .back-to-page {
  margin-top: 3px;
  margin-right: 4px;
  float: left;
  cursor: pointer;
}
.damcontent-leftsidebar .clear-filter {
  cursor: pointer;
  text-decoration: underline;
}
.damcontent-leftsidebar .tags-section {
  width: 100%;
  float: left;
  margin-top: 15px;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-title {
  width: 100%;
  height: 10.25rem;
  margin: 1rem 0;
  border-radius: 0.125rem;
}

.skeleton-cat {
  width: 100%;
  height: 1.5rem;
  margin: 1.5rem 0;
  border-radius: 0.125rem;
}
