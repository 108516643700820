.oneline-trancate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.threeline-trancate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mincounterwp {
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px) !important;
}
@media (max-width: 991px) {
  .counternumberBox {
    padding: 15px;
  }
}
