.ecom-actual-price,
.ecom-sell-price {
  display: flex;
  float: left;
  /* margin-bottom: 30px; */
}
.ecom-sell-price .sellprice {
  text-decoration: line-through;
  /* padding: 10px 10px 10px 0; */
}
.ecom-sell-price.skeletonLoad,
.ecom-actual-price.skeletonLoad {
  animation: skeleton-loading 1s linear infinite alternate;
  height: 1.75rem;
  /* width: 11ch; */
}

@media (min-width: 1280px) {
  .cart-product-list .ecom-sell-price.skeletonLoad,
  .cart-product-list .ecom-actual-price.skeletonLoad {
    width: 125px;
  }
}
@media (max-width: 600px) {
  .cart-product-list .ecom-sell-price.skeletonLoad,
  .cart-product-list .ecom-actual-price.skeletonLoad {
    width: 100px;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
.cart-product-list .ecom-actual-price,
.cart-product-list .ecom-sell-price {
  width: 125px;
  justify-content: center;
  @media (max-width: 600px) {
    width: 110px;
    justify-content: left;
  }
}
.cart-total-summary .ecom-actual-price,
.cart-total-summary .ecom-sell-price {
  width: 150px;
  text-align: right;
  justify-content: end;
}
