.hovernone:hover {
  background-color: transparent;
}
.iconFlag.header-space-between-items {
  margin-left: 4px;
  margin-right: 12px;
  padding: 10px;
}

.menu_class .singlebr {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.menu_class .singlebr.single-item {
  max-height: 68px;
  -webkit-line-clamp: 2;
}

.menu_class .doublebr {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.x-header-appbar nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: #fff; */
}

.x-header-appbar nav .logo {
  margin-right: 50px;
  max-width: 150px;
}

.x-header-appbar nav .logo img {
  cursor: pointer;
  width: auto;
}

.x-header-appbar nav .rightMenuWarp {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.x-header-appbar nav .rightMenuWarp ul {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
}

.x-header-appbar nav .rightMenuWarp ul li {
  width: auto;
  padding: 0;
  margin: 0;
}

.x-header-appbar nav .rightMenuWarp ul li a {
  min-width: inherit;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
}

.headerFlagIcon {
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  /* margin-right: 10px; */
}

.headerFlagIcon img {
  object-fit: cover;
  width: 24px;
  height: 24px;
  display: flex;
}
.mobile-header {
  padding: 0 3% !important;
}
