.borderbox {
  max-height: 230px;
  min-height: 230px;
  color: #000;
  padding: 20px;
  border: 1px solid #ced3d9;
  border-radius: 3px;
  position: relative;
  display: block;
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
}

.borderbox .title {
  margin: 5px 0 9px;
  padding-right: 40px;
}

.borderbox .icon {
  display: none;
  cursor: pointer;
  background: #d7ecfd;
  width: 34px;
  height: 34px;
  border-radius: 2px;
  position: absolute;
  right: 20px;
  top: 18px;
  align-items: center;
  justify-content: center;
}

.borderbox:hover {
  border: 1px solid #14142b;
}

.borderbox:hover .icon {
  display: flex;
}

.borderbox .icon svg {
  font-size: 18px;
}

@media (max-width: 767px) {
  .borderbox .icon {
    display: flex;
    top: 8px;
    right: 15px;
  }

  .borderbox {
    min-width: 100%;
  }
}

@media (max-width: 900px) {
  .borderbox .icon {
    display: flex;
    top: 8px;
    right: 15px;
  }

  .borderbox {
    min-width: 48%;
  }
}

@media (max-width: 600px) {
  .borderbox {
    min-width: 100%;
    margin: 10px 0;
  }
}
