.Banner7 .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.Banner7 .slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.Banner7 .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0;
}

.Banner7 .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.Banner7 .slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.Banner7 .slick-initialized .slick-slide {
  display: block;
}

.Banner7 .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.Banner7 .slick-dots {
  position: absolute;
  display: flex !important;
  padding: 0;
  margin: 0;
  cursor: pointer;
  bottom: 40px;
  right: 0;
  width: auto;
}

.Banner7 .slick-dots li {
  display: flex;
  height: 5px;
  margin-right: 5px;
  border-radius: 5px;
  width: 12px;
  overflow: hidden;
}

.Banner7 .slick-dots li.slick-active {
  width: 30px;
}

.Banner7 .slick-dots li.slick-active button {
  height: 100%;
}

.Banner7 .slick-arrow {
  position: absolute;
  width: 32px;
  height: 32px;
  background: transparent;
  border: 0;
  padding: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  cursor: pointer;
  text-indent: 200px;
  overflow: hidden;
  z-index: 1;
  display: none !important;
}

.Banner7 .slick-arrow.slick-prev {
  left: -42px;
}

.Banner7 .slick-arrow.slick-next {
  right: -42px;
}

.Banner7 .slick-arrow.slick-next:after,
.Banner7 .slick-arrow.slick-prev:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  text-indent: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.Banner7 .slick-arrow:hover:after {
  opacity: 0.7;
}

@media (max-width: 991px) {
  .Banner7 .slick-dots {
    position: relative;
    margin: 15px 0 0px 0;
    bottom: 20px;
  }

  .Banner7 .slick-track {
    margin: 0;
  }
}
