.queryBox {
  height: 130px;
  border-radius: 5px;
  position: relative;
  padding: 10px 25px 10px 10px;
  font-size: 1rem;
  overflow: auto;
  border: 1px solid rgb(217, 219, 233);
}

.queryBox > .queryBox_content {
  word-break: break-word;
}

.queryBox > .queryBoxOption {
  position: absolute;
  right: 0px;
  top: 1px;
  padding: 10px 3px;
}
