.service-showcase2 .sliderWp .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.service-showcase2 .sliderWp .slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
.service-showcase2 .sliderWp .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0;
}
.service-showcase2 .sliderWp .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.service-showcase2 .sliderWp .slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}
.service-showcase2 .sliderWp .slick-initialized .slick-slide {
  display: block;
}
.service-showcase2 .sliderWp .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.service-showcase2 .sliderWp .slick-track {
  display: flex !important;
  margin-left: -10px;
  margin-right: -10px;
}

.service-showcase2 .sliderWp .slick-slide {
  height: inherit !important;
}
.service-showcase2 .sliderWp .slick-slide > div {
  height: 100% !important;
}

.service-showcase2 .sliderWp .slick-slide {
  margin: 0 10px;
}
.service-showcase2 .sliderWp .slick-dots {
  position: absolute;
  display: flex !important;
  padding: 0;
  margin: 38px 0 0;
  cursor: pointer;
}

.service-showcase2 .sliderWp .slick-dots li {
  display: flex;
  width: 41px;
  height: 4px;
  background: #ced3d9;
  margin-right: 5px;
}
.service-showcase2 .sliderWp .slick-dots li.slick-active button {
  background: #2d2d39;
  height: 5px;
}
.service-showcase2 .sliderWp .slick-dots li button {
  width: 41px;
  height: 4px;
  background: #ced3d9;
  overflow: hidden;
  border-color: transparent;
  cursor: pointer;
}
.service-showcase2 .sliderWp .slick-arrow {
  position: absolute;
  width: 12px;
  height: 16px;
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  margin: auto 0;
  cursor: pointer;
  text-indent: 200px;
  overflow: hidden;
  z-index: 1;
}
.service-showcase2 .sliderWp .slick-arrow.slick-prev {
  left: -10px;
}
.service-showcase2 .sliderWp .slick-arrow.slick-prev:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  text-indent: 0;
  width: 11px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url('${LeftArrow}') */
}
.service-showcase2 .sliderWp .slick-arrow.slick-next {
  right: -10px;
}
.service-showcase2 .sliderWp .slick-arrow.slick-next:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  text-indent: 0;
  width: 11px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url('${RightArrow}') */
}
.service-showcase2 .sliderWp .slick-arrow:hover:after {
  opacity: 0.7;
}
@media (max-width: 991px) {
  .service-showcase2 .sliderWp .slick-dots {
    position: relative;
    margin: 22px 0 25px 0;
  }
  .service-showcase2 .sliderWp .slick-dots li,
  .service-showcase2 .sliderWp .slick-dots li button,
  .service-showcase2 .sliderWp .slick-dots li.slick-active button {
    width: 18px;
    height: 3px;
  }
  .service-showcase2 .sliderWp .slick-track {
    margin: 0;
  }
}
@media (max-width: 767px) {
  .service-showcase2 .sliderWp .slick-arrow.slick-next {
    right: 0px;
  }
  .service-showcase2 .sliderWp .slick-arrow.slick-prev {
    left: 0px;
  }
}
.service-showcase2 .sliderWp .fourbr {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.service-showcase2 .sliderWp .singlebr {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.service-showcase2 .sliderWp .text-truncated-5line {
  -webkit-line-clamp: 5;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}
.service-showcase2 .sliderWp .text-truncated-2line {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}
