.multiSlot2BG .title button {
  height: 100%;
}

.multiSlot2BG .expertise-show-case button {
  padding: 0;
}

.multiSlot2BG .button-name button {
  width: 250px;
  height: 49px;
}

.multiSlot2BG .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.multiSlot2BG .slick-slider .slick-track,
.multiSlot2BG .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.multiSlot2BG .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.multiSlot2BG .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.multiSlot2BG .slick-track:before,
.multiSlot2BG .slick-track:after {
  display: table;
  content: "";
}

.multiSlot2BG .slick-initialized .slick-slide {
  display: block;
}

.multiSlot2BG .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.multiSlot2BG .slick-prev {
  left: 8px;
}

.multiSlot2BG .slick-prev,
.multiSlot2BG .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 33px;
  height: 60px;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  border-radius: 5px;
  z-index: 1;
  transform: translateY(-50%);
}

.multiSlot2BG .slick-prev:before,
.multiSlot2BG .slick-next:before {
  font-size: 32px;
  line-height: 1;
  margin-left: 0px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.multiSlot2BG .slick-prev:before {
  content: "‹";
}

.multiSlot2BG .slick-next {
  right: 8px;
}

.multiSlot2BG .slick-next:before {
  content: "›";
  margin-left: 0px;
}
.multiSlot2BG .singlebr {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.multiSlot2BG .doublebr {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.multiSlot2BG .view-more {
  font-size: 14px !important;
  transform: translateX(-100%);
  transition: transform 0.25s ease;
}

.multiSlot2BG .overlay-wrapper:hover .view-more {
  transform: translateX(0);
}

@media screen and (max-width: 1024px) {
  .multiSlot2BG .view-more {
    transform: translateX(0);
  }
}

@media only screen and (min-width: 600px) {
  .multiSlot2BG .button-name button {
    width: 100%;
  }
}
