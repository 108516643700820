.testimonialSlider .slick-slider {
  position: relative;
}
.testimonialSlider .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
}
.testimonialSlider .slick-slide {
  display: none;
  float: left;
  height: inherit !important;
  min-height: 1px;
}
.testimonialSlider .slick-track {
  display: flex;
  margin: 0 -10px;
}
.testimonialSlider .slick-initialized .slick-slide {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
}
.testimonialSlider .slick-initialized .slick-slide.slick-active {
  margin-right: 0;
}
.testimonialSlider .slick-initialized .slick-slide > div {
  width: 100%;
}
.testimonialSlider .slick-loading .slick-slide {
  visibility: hidden;
}
.testimonialSlider .slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.testimonialSlider .slick-arrow.slick-hidden {
  display: none;
}
.testimonialSlider .slick-prev,
.testimonialSlider .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: -40px;
  display: block;
  width: 38px;
  height: 38px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: #fff;
  border: none;
  outline: none;
  background: #818188;
}

.testimonialSlider .slick-prev:hover,
.testimonialSlider .slick-prev:focus,
.testimonialSlider .slick-next:hover,
.testimonialSlider .slick-next:focus {
  color: #fff;
  outline: none;
  background: #2d2d39;
}
.testimonialSlider .slick-next.slick-disabled,
.testimonialSlider .slick-prev.slick-disabled {
  background: #e1e1e1;
  cursor: not-allowed;
}
.testimonialSlider .slick-prev:hover:before,
.testimonialSlider .slick-prev:focus:before,
.testimonialSlider .slick-next:hover:before,
.testimonialSlider .slick-next:focus:before {
  opacity: 1;
}
.testimonialSlider .slick-prev.slick-disabled:before,
.testimonialSlider .slick-next.slick-disabled:before {
  opacity: 1;
}
.testimonialSlider .slick-prev:before,
.testimonialSlider .slick-next:before {
  font-size: 20px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.testimonialSlider .slick-prev {
  display: none;
  right: 60px;
  left: auto;
}
.testimonialSlider .slick-prev:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  text-indent: 0;
  width: 11px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  margin: auto;
  filter: invert(0);
}
.testimonialSlider .slick-next {
  right: 0px;
}
.testimonialSlider .slick-next:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  text-indent: 0;
  width: 11px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  margin: auto;
  filter: invert(0);
}
@media (max-width: 767px) {
  .testimonialSlider .slick-track {
    margin: 0;
    margin-right: -2px;
  }
  .testimonialSlider .slick-initialized .slick-slide {
    margin-left: 0;
    margin-right: 2px;
  }
}
