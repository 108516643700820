.listTimelineItem:first-child .listTimelineDot {
  border: 50% !important;
  width: auto !important;
  padding: 8px !important;
}

.listTimelineItem {
  min-height: 60px !important;
}

.blogContent .listTimelineItem:first-child {
  margin-top: 0px !important;
}

.listTimeline {
  padding: 0px !important;
  margin-top: 0px !important;
}

.listTimelineDot {
  padding: 0px 12px !important;
  margin: 0px !important;
  font-size: 14px;
  justify-content: center;
  border-radius: 50px !important;
  min-width: 62px;
  margin-top: 5px;
  height: 28px !important;
  align-items: center;
}

.listTimelineItem::before {
  content: unset !important;
}

.listTypography {
  padding-top: 0px !important;
}

.listTimelineConnector {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.listTimelineDot.WithoutBorder {
  padding: 0px 15px 8px 15px !important;
  margin: 0px !important;
  border-color: transparent !important;
  font-size: 14px;
  justify-content: center;
  border-radius: 50px !important;
}

.overflowtext {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.arrowIcon {
  position: relative;
  bottom: 2px;
  font-size: 18px !important;
  cursor: pointer;
}

.downArrow {
  bottom: 10px;
  font-size: 18px !important;
}

/* *=====Scrollbar CSS=====*/
/* width */

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */

*::-webkit-scrollbar-track {
  background: transparent;

  box-shadow: inset 0 0 5px #dddddd;

  border-radius: 4px;

  border-left: 1.5px solid transparent;

  border-right: 1.5px solid transparent;
}

/* Handle */

*::-webkit-scrollbar-thumb {
  background: #b1b1b1;

  border-radius: 4px;
}

@media screen and (max-width: 767px) {
  .listTimelineItem {
    flex-direction: column;
  }

  .blogContent .listTimelineItem {
    margin-top: 15px !important;
  }

  .Platform-x-TextField-root {
    padding: 0px !important;
  }
}

/* styles required for blogs */

.blogAsset {
  height: 225px;
  width: 400px;
  border-radius: 3px;
  /* margin: 0 0 21px 0; */
}

.blogImage {
  /* margin-top: 21px; */
  height: 225px;
  width: 400px;
  border-radius: 3px;
  @media screen and (max-width: 767px) {
    height: 135px;
    width: 240px;
  }
}

.blogVideo {
  height: 225px;
  width: 400px;
  border-radius: 3px;
  @media screen and (max-width: 767px) {
    height: 135px;
    width: 240px;
  }
  /* margin-top: 21px; */
}

.contentTypeBox {
  /* min-height: 165px; */
  height: 225px;
  width: 400px;
  border-radius: 3px;
  @media screen and (max-width: 767px) {
    /* min-height: 135px; */
    height: 135px;
    width: 240px;
  }
  /* margin-top: 21px; */
}

.onlydesc {
  display: inline-block;
}
