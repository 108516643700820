.createusertophead {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 999;
  background: #fff;
  border-bottom: solid 1px #ced3d9;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}
.createusertophead .backarrow {
  color: #2d2d39;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.createuserbottomhead {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px #ced3d9;
  justify-content: space-between;
  padding: 13px 20px;
  position: sticky;
  top: 61px;
  background: #fff;
  z-index: 2;
}
.createuserbottomhead .backarrow {
  color: #2d2d39;
  cursor: pointer;
  border: 1px solid #d9dbe9;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  display: flex;
}
.createuserbottomhead .usericon {
  background: #dfe4ff;
  border-radius: 5px;
  margin-right: 10px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.UserroleUserDetailsHead {
  max-width: 800px;
  border-radius: 6px;
  overflow: hidden;
  margin: auto;
  border: solid 1px #f5f6f8;
}
.UserroleUserDetailsHead .title {
  padding: 10px 20px;
  background: #f3faff;
}

@media (max-width: 767px) {
  .createuserbottomhead {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
    padding: 10px;
  }
  .createuserbottomhead .backarrow {
    display: none;
  }
  .createusertophead {
    padding: 10px;
  }
  .createusertophead .backarrow {
    display: flex;
  }
  .UserroleUserDetailsHead {
    max-width: 100%;
  }
}
