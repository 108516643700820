.shipping-detail-section {
  padding: 6px 24px;
  border-radius: 3px;
  margin-bottom: 30px;
}

.shipping-detail-section .shipping-detail-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* padding-bottom: 20px;
  padding-top: 20px; */
}

.shipping-detail-section .shipping-detail-content:last-child {
  border: none;
}

.shipping-detail-section .shipping-detail-content .item.name {
  min-width: 140px;
}

.shipping-detail-section .shipping-detail-content .item.action {
  min-width: 80px;
  text-align: right;
}

.shipping-detail-section .shipping-detail-content .items {
  width: calc(100% - 80px);
  display: flex;
  justify-content: left;
}

.shipping-detail-section .shipping-detail-content .item.detail-text {
  width: calc(100% - 120px);
  overflow-wrap: break-word;
}

.shipping-detail-section
  .shipping-detail-content
  .shipping-detail-section
  .shipping-detail-content
  .item {
  flex: 1 0 21%;
  margin: 5px;
  text-align: left;
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .shipping-address-wrapper-left {
    padding-right: 0px;
  }

  .shipping-detail-section .shipping-detail-content .item.name {
    min-width: 80px;
  }

  .shipping-detail-section .shipping-detail-content .item.detail-text {
    width: calc(100% - 80px);
    overflow-wrap: break-word;
  }
  .shipping-address-wrapper-left .bottom-button-text-wrapper .black-button {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
