.eventDetailsWrapper .Platform-x-SvgIcon-root {
  color: #5c6574;
}

.eventlinkIcon {
  transform: rotate(-45deg);
}

.overflowtext {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.showDetailsArea {
  left: 45%;
  position: absolute;
  bottom: 20px;
}

.titleText {
  margin-top: 5px !important;
}

@media (max-width: 767px) {
  .overflowtext {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .flexOrder1 {
    order: 1;
  }

  .flexOrder2 {
    order: 2;
  }

  .flexOrder3 {
    order: 3;
  }

  .showDetailsArea {
    width: 100%;
    margin: 10px 10px 20px;
    position: unset;
    left: 0%;
  }

  .titleText {
    margin-top: 200px !important;
  }
}
