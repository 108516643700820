.cart-product-list-wrapper .button-wrapper button {
  flex-grow: 1;
  justify-content: left;
  text-transform: capitalize;
  text-decoration: none;
}
.cart-product-list-wrapper .button-wrapper {
  float: left;
}
.cart-product-list-wrapper .button-wrapper button:hover {
  background: transparent;
}

.cart-product-list-wrapper .skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
