.cardslider .slick-next {
  right: 10px;
}

.cardslider .slick-prev {
  left: 10px;
}

.cardslider .ctabox {
  border-top: 0;
  border-radius: 0;
  display: flex;
  width: 100%;
  padding: 10px 18px;
  min-height: 61px;
  text-transform: none;
  align-items: center;
  justify-content: space-between;
}

.cardslider .imagecolorbox {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 33px 38px 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.cardslider .slick-next.slick-disabled,
.cardslider .slick-prev.slick-disabled {
  visibility: hidden;
}

.cardslider .slick-prev:hover,
.cardslider .slick-prev:focus,
.cardslider .slick-next:hover,
.cardslider .slick-next:focus {
  background: rgba(45, 45, 57, 0.6);
}

.cardslider .imgbox {
  object-fit: cover;
  max-width: 100%;
  max-height: 185px;
  min-height: 185px;
}

.ctabox .icon {
  display: flex;
  align-items: center;
}

.cardslider .ctabox:hover {
  background-color: transparent;
}

.cardslider .slick-track {
  display: flex;
}

.cardslider .slick-slide {
  margin-right: 10px;
  margin-left: 10px;
}

@media (max-width: 767px) {
  .cardslider .imagecolorbox {
    padding: 23px 28px 0;
  }
}
