.cardbox {
  text-align: center;
  max-width: 452px;
  padding: 15px;
}

.cardbox h2 {
  margin: 45px 0 25px 0;
}

.cardbox p {
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .cardbox h2 {
    margin: 15px 0;
  }

  .cardbox p {
    margin-bottom: 15px;
  }
}
