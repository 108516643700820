.descAsset {
  margin: 10px 0;
  width: 100%;
}
.DivEnable {
  background: #2633c7;
  border-radius: 3px;
}
.DivDisable {
  background: #a0a3bd;
  border-radius: 3px;
}
.DivButton {
  min-width: 175px;
}
@media screen and (min-width: 750px) {
  .descAsset {
    height: 350px;
  }
}
@media screen and (min-width: 900px) {
  .descAsset {
    height: 500px;
  }
}
