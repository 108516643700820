/* .Platform-x-TimelineItem-root:first-child .Platform-x-TimelineDot-root {
    border: 50% !important;
    width: auto !important;
    height: auto !important;
    padding: 8px !important;
} */

.platx-blog-timeline .Platform-x-Timeline-root {
  padding: 0px !important;
}

.platx-blog-timeline .Platform-x-TimelineDot-root {
  margin: 0px !important;
  border-color: #5c6574 !important;
  font-size: 12px;
  justify-content: center;
  border-radius: 50px !important;
  min-width: 90px;
  height: 32px;
  border-width: 1px;
  align-items: center;
}

/* .Platform-x-TimelineItem-root:first-child{
 margin-left: 3px !important;
} */

.platx-blog-timeline .Platform-x-TimelineItem-root::before {
  content: unset !important;
}

.platx-blog-timeline .Platform-x-Typography-root {
  padding-top: 2px !important;
}

.platx-blog-timeline .Platform-x-SvgIcon-root {
  color: #5c6574 !important;
}

.platx-blog-timeline .Platform-x-TimelineConnector-root {
  width: auto !important;
  background-color: transparent !important;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  border: 1px dashed #5c6574 !important;
}

.infinite-scroll-component {
  overflow: hidden !important;
}

.dotSeprator {
  width: 4px;
  height: 4px;
  border-radius: 20px;
  background-color: #ced3d9;
}

.highlighterArea {
  display: inline-block !important;
  /* width: 170px; */
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  margin-right: 10px;
}

.iframeBoxContainer {
  border: 1px solid #f5f5f5;
  overflow: auto;
  height: 166px;
  width: 375px;
}

.contentTypeBox {
  width: 100%;
  height: 200px;
  margin: 21px 0 21px 0;
  cursor: pointer;
}

/* .onlydesc {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    margin: 0;
} */

span.descBox {
  font-size: 12px;
}

.searchArea {
  height: 40px;
  min-height: 40px;
  width: 100%;
  margin-bottom: 35px;
}

.author-name {
  margin-right: 0px !important;
  padding-right: 5px !important;
}
