.pageGraph .noxyAxsis .recharts-cartesian-axis-line,
.noxyAxsis .recharts-cartesian-axis-tick-line {
  display: none;
}
.recharts-legend-wrapper.pie .recharts-default-legend {
  padding: 0;
  margin: 0;
}
.recharts-legend-wrapper.pie {
  margin-top: -20px;
  text-align: center;
}
.recharts-legend-wrapper.pie ul li {
  list-style-type: none;
  display: inline-flex;
  margin-right: 10px;
}
.recharts-legend-wrapper.pie ul li .boxshape {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  float: left;
}
.recharts-legend-wrapper.pie ul li .text,
.recharts-text recharts-label {
  font-size: 12px;
}

.pieChartneedle.pageGraph {
  padding-right: 0;
}
.pieChartneedle.pageGraph .recharts-wrapper {
  padding: 0 15px 0 15px;
}
.graphContainerSpacing {
  padding: 15px;
  margin-top: 15px;
}
.snapshortsChart .graphContainerSpacing {
  padding: 0;
}
.snapshortsChart .graphContainerSpacing .chartHeading {
  text-transform: none;
}
.graphContainerSpacing .noDataFound {
  width: 100%;
  text-align: center;
}
.graphContainerSpacing .chartHeading {
  width: 100%;
  float: left;
  padding: 15px;
  /* margin-top: 14px; */
  text-transform: uppercase;
}

.chartSkeltonWrapper {
  margin-left: 16px;
  border: solid 1px #ced3d9;
  margin-bottom: 20px;
  border-radius: 4px;
}
.chartSkeltonWrapper .Platform-x-Paper-root {
  margin: 10px auto;
}
.snapshortsChart .chartContainer .chartBox {
  display: none;
}
.snapshortsChart .chartContainer .chartBox:nth-child(1),
.snapshortsChart .chartContainer .chartBox:nth-child(2) {
  display: block;
}
