.dynamic-ecommerce-prelem-slider .transparentButton {
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
}

.dynamic-ecommerce-prelem-slider .nextPreviousButton {
  font-size: 25px !important;
  cursor: pointer;
}

.dynamic-ecommerce-prelem-slider .image {
  object-fit: contain;
  width: 100%;
}

.dynamic-ecommerce-prelem-slider .slick-slider {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.dynamic-ecommerce-prelem-slider .slick-slider .slick-track,
.dynamic-ecommerce-prelem-slider .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.dynamic-ecommerce-prelem-slider .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.dynamic-ecommerce-prelem-slider .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.dynamic-ecommerce-prelem-slider .slick-track:before,
.dynamic-ecommerce-prelem-slider .slick-track:after {
  display: table;
  content: "";
}

.dynamic-ecommerce-prelem-slider .slick-initialized .slick-slide {
  display: block;
}

.dynamic-ecommerce-prelem-slider .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.dynamic-ecommerce-prelem-slider .slick-prev,
.dynamic-ecommerce-prelem-slider .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0;
  border: none;
  outline: none;
}
.dynamic-ecommerce-prelem-slider .slick-prev .Platform-x-Typography-root {
  line-height: 1.3;
  margin: 0 10px;
}
.dynamic-ecommerce-prelem-slider .slick-prev:before,
.dynamic-ecommerce-prelem-slider .slick-next:before {
  display: none;
}

.dynamic-ecommerce-prelem-slider .tabbtn {
  color: #89909a;
}

.dynamic-ecommerce-prelem-slider .image-container {
  position: relative;
}

.dynamic-ecommerce-prelem-slider .ellipsisTextof2lines {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.dynamic-ecommerce-prelem-slider .ellipsisTextof1line {
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.dynamic-ecommerce-prelem-slider .image-container::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: #000;
  opacity: 0.03; */
  pointer-events: none;
  content: "";
  display: block;
}
@media screen and (max-width: 1024px) {
  .dynamic-ecommerce-prelem-slider .hidden-button {
    position: absolute;
    opacity: 1 !important;
    min-width: 120px;
    transform: translate(-50%, -50%);
    left: 50%;
    /* padding: 10px 20px; */
    border: none;
    border-radius: 3px;
    text-align: center;
    bottom: -10px;
  }
}
@media screen and (min-width: 1025px) {
  .dynamic-ecommerce-prelem-slider .hidden-button {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, 100%);
    /* padding: 10px 20px; */
    border: none;
    opacity: 0;
    border-radius: 3px;
    text-align: center;
    transition:
      transform 0.6s ease-out,
      opacity 0.6s ease-out;
    z-index: -1;
  }

  .dynamic-ecommerce-prelem-slider .image-container:hover .hidden-button {
    transform: translate(-50%, -50%);
    opacity: 1;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    z-index: 1;
  }
}
