.shipping-address-wrapper {
  /* padding: 20px 30px 0px 30px; */
}
.shipping-address-wrapper .shipping-address-wrapper-right {
  padding-left: 15px;
}
.shipping-address-wrapper .shipping-form-control {
  width: 100%;
  float: left;
}
.shipping-address-wrapper .shipping-address-wrapper-right .right-section {
  padding: 25px 25px 10px 30px;
  float: left;
  width: 100%;
}

@media screen and (max-width: 1023px) {
  .shipping-address-wrapper .shipping-address-wrapper-right {
    padding-left: 0px;
  }
  .shipping-address-wrapper {
    /* padding: 16px; */
  }
  .shipping-address-wrapper .shipping-address-wrapper-right {
    padding-left: 0px;
  }
}
