.spinAndWheelSection #spin_the_wheel {
  display: inline-block;
  position: absolute;
  width: 280px;
  top: 20px;
  transform: translate(-50%, 0px);
  border: solid 3px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}
.spinAndWheelSection .wheelWrapperSection {
  position: relative;
  margin: 0px auto;
}
.spinAndWheelSection .outerCircle {
  background: linear-gradient(
    0deg,
    #fffb90 0%,
    #fbe978 14%,
    #f8dc65 24%,
    #e6c758 27%,
    #c49f40 34%,
    #ac832f 40%,
    #9e7225 45%,
    #996c22 49%,
    #9d7125 52%,
    #a98030 56%,
    #bd9a42 60%,
    #d9be5a 64%,
    #fbe878 69%,
    #ffffaa 77%,
    #fbe878 83%,
    #a4631b 100%
  );
  box-shadow:
    0 0 0 1px #fbf8f8,
    0 0px 20px 5px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinAndWheelSection #wheel {
  display: block;
  position: relative;
}

.spinAndWheelSection #spin {
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20%;
  height: 20%;
  margin: -10%;
  background: #fff;
  color: #fff;
  box-shadow:
    0 0 0 8px currentColor,
    0 0px 15px 5px rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  transition: 0.8s;
}

.spinAndWheelSection #spin::after {
  content: "";
  position: absolute;
  top: -17px;
  border: 10px solid transparent;
  border-bottom-color: currentColor;
  border-top: none;
}

@keyframes rotateGradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
