.refershEnabled {
  width: 27px;
  height: 27px;
  flex-grow: 0;
  border-radius: 20px;
  background-color: #333;
  border: solid 1px #333;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.refershDisabled {
  width: 27px;
  height: 27px;
  flex-grow: 0;
  border-radius: 20px;
  background-color: #fff;
  border: solid 1px #ced3d9;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media only screen and (max-width: 576px) {
  .refershEnabled {
    margin: 0px 8px 8px 0px;
  }
  .refershDisabled {
    margin: 0px 0px 8px 0px;
  }
}

@media only screen and (min-width: 992px) {
  .refershEnabled {
    margin: 0px 8px 8px 0px;
  }
  .refershDisabled {
    margin: 0px 0px 8px 0px;
  }
}
