.CardSliderImageUpload .slick-prev,
.CardSliderImageUpload .slick-next {
  width: 44px;
  height: 44px;
  background: rgba(45, 45, 57, 0.6);
  z-index: 9;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.CardSliderImageUpload .slick-next {
  right: 10px;
}

.CardSliderImageUpload .slick-prev {
  left: 0px;
}

.CardSliderImageUpload .ctabox {
  border-top: 0;
  border-radius: 0;
  display: flex;
  width: 100%;
  padding: 10px 18px;
  min-height: 61px;
  text-transform: none;
  align-items: center;
  justify-content: space-between;
}

.CardSliderImageUpload .imagecolorbox {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 33px 38px 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.CardSliderImageUpload .slick-next.slick-disabled,
.CardSliderImageUpload .slick-prev.slick-disabled {
  visibility: hidden;
}

.CardSliderImageUpload .slick-prev:hover,
.CardSliderImageUpload .slick-prev:focus,
.CardSliderImageUpload .slick-next:hover,
.CardSliderImageUpload .slick-next:focus {
  background: rgba(45, 45, 57, 0.6);
}

.CardSliderImageUpload .imgbox {
  object-fit: cover;
  max-height: 80px;
  min-height: 80px;
  width: 100%;
  border-radius: 5px;
}

.CardSliderImageUpload .card {
  /* border: 1px solid #ced3d9; */
  /* color: #000; */
  position: relative;
  border-radius: 5px;
  display: block;
  opacity: 0.5;
}

.CardSliderImageUpload .card:hover {
  cursor: pointer;
  opacity: 0.8;
}

.CardSliderImageUpload .ctabox .icon {
  display: flex;
  align-items: center;
}

.CardSliderImageUpload .ctabox:hover {
  background-color: transparent;
}

.CardSliderImageUpload .slick-track {
  display: flex;
}

.CardSliderImageUpload .slick-slide {
  margin-right: 10px;
  /* margin-left: 10px; */
}
.CardSliderImageUpload .card.selected {
  /* Define styles for the selected card */
  border: 1px solid #4b9ef9; /* Change the border color to blue when selected */
  opacity: 1;
}
@media (max-width: 767px) {
  .CardSliderImageUpload .imagecolorbox {
    padding: 23px 28px 0;
  }
}
.CardSliderImageUpload .slick-track {
  margin: 0;
}
