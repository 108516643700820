html {
  scroll-behavior: smooth;
}
.videobanner-2 .container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.videobanner-2 .banner {
  position: relative;
  overflow: hidden;
}
.videobanner-2 .react-player-anime {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* mix-blend-mode: screen; */
}
.videobanner-2 .backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.videobanner-2 .mouse-indicator {
  position: absolute;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  bottom: 35px;
}

.videobanner-2 .mouse-indicator .mouse-down {
  cursor: pointer;
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -webkit-animation: nudgeMouse 2.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
  animation: nudgeMouse 2.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
}

.videobanner-2 .mouse-indicator .mouse-down:before {
  position: absolute;
  content: "";
  display: block;
  left: 50%;
  margin-left: -1px;
  top: 19px;
  width: 2px;
  height: 6px;
  border-radius: 10px;
  transition: background-color 0.55s cubic-bezier(0.5, 0.1, 0.07, 1);
  -webkit-animation: trackBallSlide 2.4s cubic-bezier(0, 0, 0.725, 1) infinite;
  animation: trackBallSlide 2.4s cubic-bezier(0, 0, 0.725, 1) infinite;
}

.videobanner-2 .mouse-indicator .mouse-down svg {
  width: 22px;
}

@-webkit-keyframes trackBallSlide {
  0% {
    opacity: 1;
    -webkit-transform: scaleY(1) translateY(-12px);
    transform: scaleY(1) translateY(-12px);
  }
  45% {
    opacity: 0;
    -webkit-transform: scaleY(0.5) translateY(12px);
    transform: scaleY(0.5) translateY(12px);
  }
  46% {
    opacity: 0;
    -webkit-transform: scaleY(1) translateY(-12px);
    transform: scaleY(1) translateY(-12px);
  }
  65%,
  100% {
    opacity: 1;
    -webkit-transform: scaleY(1) translateY(-12px);
    transform: scaleY(1) translateY(-12px);
  }
}

@keyframes trackBallSlide {
  0% {
    opacity: 1;
    -webkit-transform: scaleY(1) translateY(-12px);
    transform: scaleY(1) translateY(-12px);
  }
  45% {
    opacity: 0;
    -webkit-transform: scaleY(0.5) translateY(12px);
    transform: scaleY(0.5) translateY(12px);
  }
  46% {
    opacity: 0;
    -webkit-transform: scaleY(1) translateY(-12px);
    transform: scaleY(1) translateY(-12px);
  }
  65%,
  100% {
    opacity: 1;
    -webkit-transform: scaleY(1) translateY(-12px);
    transform: scaleY(1) translateY(-12px);
  }
}

@keyframes nudgeMouse {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  45% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  65%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes nudgeMouse {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  45% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  65%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@media (max-width: 767px) {
  .mouse-indicator {
    bottom: 70px;
  }
}
