.image-video-gallery .slick-next.slick-disabled:before,
.image-video-gallery .slick-prev.slick-disabled:before {
  opacity: 0.25;
}
.image-video-gallery .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.image-video-gallery .slick-slider .slick-track,
.image-video-gallery .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.image-video-gallery .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0 -20px;
  padding: 0;
}

.image-video-gallery .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.image-video-gallery .slick-track:before,
.image-video-gallery .slick-track:after {
  display: table;
  content: "";
}

.image-video-gallery .slick-initialized .slick-slide {
  display: block;
}

.image-video-gallery .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.image-video-gallery .slick-slide .Platform-x-Box-root {
  padding: 0 15px;
  box-sizing: border-box;
}
@media (max-width: 767px) {
  .image-video-gallery .slick-slide .Platform-x-Box-root {
    padding: 0 10px;
  }
}
.image-video-gallery .slick-slide img,
.image-video-gallery .slick-slide video,
.image-video-gallery .slick-slide iframe {
  display: block;
  width: 100%;
  height: 230px !important;
  margin: 0 auto;
  object-fit: cover;
  cursor: pointer;
}
@media (max-width: 767px) {
  .image-video-gallery .slick-slide img,
  .image-video-gallery .slick-slide video,
  .image-video-gallery .slick-slide iframe {
    height: 180px !important;
  }
}
.image-video-gallery .slick-prev {
  left: -20px;
}
.image-video-gallery .slick-prev,
.image-video-gallery .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 40px;
  height: 40px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  z-index: 1;
}
.image-video-gallery .slick-prev:before,
.image-video-gallery .slick-next:before {
  font-size: 32px;
  line-height: 1;
  color: white;
  margin-left: -3px;
  margin-bottom: 6px;
  display: inline-block;
}
.image-video-gallery .slick-prev:before {
  content: "‹";
  margin-top: -6px;
}
.image-video-gallery .slick-next {
  right: -20px;
}
.image-video-gallery .slick-next:before {
  content: "›";
  margin-left: 3px;
  margin-top: -6px;
}
.video-player-overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 0 !important;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.video-player-overlay > div {
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
