.content {
  -webkit-user-modify: read-only;
  user-select: none;
  margin: 10px 0;
}
blockquote {
  margin: 0;
  border-left: 3px solid black;
  padding: 5px 10px;
  font-style: italic;
}
.placeholdertext {
  font-size: 18px;
  line-height: 1.8;
}
