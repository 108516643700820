.resultCards .text-truncated-3line {
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}
.resultCards .text-truncated-1line {
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.searchResultSection::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #f5f5f5;
}
.searchResultSection::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.searchResultSection::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: grey;
}
