.cart-product-list .cart-product-list-item {
  position: relative;
  /* border-radius: 8px; */
  /* padding: 14px; */
}
.cart-product-list .cart-product-list-item .product-list-image {
  box-shadow: none;
}
.cart-product-list
  .cart-product-list-item
  .product-list-image
  .product-list-image-container
  .list-image {
  height: 75px;
  object-fit: contain;
}
.cart-product-list .cart-product-list-item .product-list-image::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.02;
  pointer-events: none;
  content: "";
  display: block;
}
