/* Looper styles */
@keyframes slideAnim {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
.looper {
  width: 100%;
  overflow: hidden;
}
.looper:last-child {
  margin-left: -100px;
  width: calc(100% + 100px);
}
.looper__innerList {
  display: flex;
  justify-content: center;
  width: fit-content;
}
.looper__innerList[data-animate="true"] .looper__listInstance {
  animation: slideAnim linear infinite;
}
.looper__listInstance {
  display: flex;
  width: max-content;
  animation: none;
}
.looper-list video {
  border-radius: 8px;
}
