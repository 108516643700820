.imageThumbnailWrapper,
.videoThumbnailWrapper,
.contentThumbnailWrapper {
  position: relative;
  width: 40px;
  height: 40px;
}
.imageThumbnailWrapper button,
.videoThumbnailWrapper button,
.contentThumbnailWrapper button {
  position: absolute;
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0;
}
.img-background {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.buttonLeftSection {
  margin-left: 10px;
}
.buttonLeftSection .leftBorder {
  border-color: transparent;
}
