.damcontent-topbar .fixed-screen-inner .back-to-page {
  margin-top: 5px;
  margin-right: 4px;
  height: 18px;
  float: left;
  cursor: pointer;
}

.damcontent-topbar .fixed-screen-inner .top-icons {
  width: 15px;
  float: right;
  cursor: pointer;
}

.damcontent-topbar .fixed-screen-inner .icon-container {
  min-width: 56px;
  width: auto;
}

.damcontent-topbar .fixed-screen-inner .search-item-container {
  width: 100%;
}

.damcontent-topbar .fixed-screen-inner .search-item-container.small-device {
  margin-top: -14px;
}

.damcontent-topbar
  .fixed-screen-inner
  .search-item-container.small-device
  .Platform-x-FormControl-root
  #asynchronousSearch {
  padding-right: 30px;
}

.damcontent-topbar
  .fixed-screen-inner
  .search-item-container.small-device
  .Platform-x-Autocomplete-root {
  display: block !important;
}

.damcontent-topbar
  .fixed-screen-inner
  .search-item-container.small-device
  .Platform-x-Autocomplete-root
  .Platform-x-InputBase-root {
  height: 47px;
  padding-top: 5px;
}

.damcontent-topbar
  .fixed-screen-inner
  .search-item-container.small-device
  .Platform-x-Box-root
  svg {
  display: none !important;
}

.damcontent-topbar .fixed-screen-inner .icon-inside-search {
  position: absolute;
  right: 30px;
  top: 16px;
}

.damcontent-topbar .fixed-screen-inner .icon-inside-search svg {
  cursor: pointer;
}

.Platform-x-Drawer-root .Platform-x-Paper-root {
  width: 100%;
}
