.ecommerceProductDetailsTextArea .ellipsisTextof2lines {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}
.ecommerceProductDetailsTextArea .ellipsisTextof4lines {
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}
.product-detail-section .product-large-image-wrapper .detail-large-image-card ::after,
.product-detail-section .product-small-image-wrapper .product-thumbnail ::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: #000;
  opacity: 0.03; */
  pointer-events: none;
  content: "";
  display: block;
}
/* .product-detail-section .product-large-image-wrapper .detail-large-image-card .large-image {
  padding: 20px 0px
} */

.ecommerceProductDetailsTextArea .skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.ecommerceProductDetailsTextArea .skeleton-text {
  width: 100%;
  height: 2.25rem;
  margin-bottom: 1rem;
  border-radius: 0.125rem;
}

.ecommerceProductDetailsTextArea .skeleton-description {
  height: 1rem;
  margin-bottom: 0.5rem;
}

.ecommerceProductDetailsTextArea .skeleton-price {
  height: 2rem;
  margin: 30px 0px;
  width: 25%;
}
