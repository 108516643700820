.product {
  padding: 10px 20px 10px 0px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 10px;
}

.image-container {
  position: relative;
  /* margin-top:15px; */
}
.image-container::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: #000;
  opacity: 0.03; */
  pointer-events: none;
  content: "";
  display: block;
}
.ellipsisTextof2lines {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.ellipsisTextof1line {
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}
@media screen and (max-width: 1024px) {
  .hidden-button {
    position: absolute;
    opacity: 1;
    min-width: 120px;
    transform: translate(-50%, -50%);
    left: 50%;
    border: none;
    border-radius: 3px;
    text-align: center;
    bottom: -10px;
  }
  .product {
    padding: 10px 10px 10px 0px;
  }
}
@media screen and (min-width: 1025px) {
  .hidden-button {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-50%, 100%);
    border: none;
    opacity: 0;
    border-radius: 3px;
    text-align: center;
    transition:
      transform 0.6s ease-out,
      opacity 0.6s ease-out;
    cursor: pointer;
    z-index: -1;
  }
  .image-container:hover .hidden-button {
    transform: translate(-50%, -50%);
    opacity: 1;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    z-index: 1;
  }
}
