.createarticletophead {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 2;
  background: #fff;
  border-bottom: solid 1px #ced3d9;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  min-height: 60px;
}
.createarticletophead .backarrow {
  color: #2d2d39;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.createarticlebottomhead {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* border-bottom: solid 1px #ced3d9; */
  margin-bottom: 29px;
  justify-content: space-between;
  padding: 13px 20px;
  padding-right: 0px;
  padding-top: 0px;
  position: absolute;
  top: 80px;
  background: #fff;
  z-index: 2;
}
.createarticlebottomhead .backarrow {
  color: #2d2d39;
  cursor: pointer;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  display: flex;
  border: 1px solid #d9dbe9;
}
.createarticlebottomhead .backspace {
  cursor: pointer;
}
.backspace {
  cursor: pointer;
}
@media (max-width: 767px) {
  .createarticlebottomhead {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
    padding: 10px;
  }
  .createarticlebottomhead .backarrow {
    display: none;
  }
  .createarticletophead {
    padding: 8px 10px;
  }
  .createarticletophead .backarrow {
    display: flex;
  }
}
