.service-card1 .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.service-card1 .slick-slider .slick-track,
.service-card1 .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.service-card1 .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0 -10px;
  padding: 0;
}

.service-card1 .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.service-card1 .slick-track:before,
.service-card1 .slick-track:after {
  display: table;
  content: "";
}

.service-card1 .slick-initialized .slick-slide {
  display: block;
}

.service-card1 .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.service-card1 .slick-prev {
  left: -20px;
}

.service-card1 .slick-prev,
.service-card1 .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 40px;
  height: 40px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  z-index: 1;
}

.service-card1 .slick-prev:before,
.service-card1 .slick-next:before {
  font-size: 32px;
  line-height: 1;
  color: white;
  margin-left: -3px;
  margin-bottom: 6px;
  display: inline-block;
}

.service-card1 .slick-prev:before {
  content: "‹";
}

.service-card1 .slick-next {
  right: -20px;
}

.service-card1 .slick-next:before {
  content: "›";
  margin-left: 3px;
}

.service-card1 .slick-track {
  display: flex !important;
}

.service-card1 .slick-slide {
  height: inherit !important;
}

.service-card1 .slick-slide > div {
  height: 100% !important;
}

.service-card1 .slick-slide {
  margin: 0 10px;
}
