.ServiceCard4Slider .slick-initialized .slick-slide {
  display: block;
}

.ServiceCard4Slider .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.ServiceCard4Slider .slick-prev,
.ServiceCard4Slider .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  bottom: 0;
  right: 453px;
  display: flex !important;
  width: 7%;
  /* height: calc(12.5vh - 60px); */
  padding: 0;
  cursor: pointer;
  border: none;
  outline: none;
  z-index: 1;
  top: auto;
  left: auto;
  transform: none;
}

.ServiceCard4Slider .slick-next {
  right: 0;
}

.ServiceCard4Slider .slick-prev .Platform-x-Typography-root {
  line-height: 1.3;
  margin: 0 10px;
}

.ServiceCard4Slider .slick-prev:before,
.ServiceCard4Slider .slick-next:before {
  display: none;
}

.ServiceCard4Slider .slick-next {
  width: calc(43% - 103px);
}

@media (max-width: 991px) {
  .ServiceCard4Slider .slick-prev,
  .ServiceCard4Slider .slick-next {
    height: calc(9.5vh - 20px);
  }

  .ServiceCard4Slider .slick-prev {
    width: 35%;
  }

  .ServiceCard4Slider .slick-next {
    width: calc(35% - 85px);
  }
}
