.blogTilesBg .title button {
  height: 100%;
}
.blogTilesBg .expertise-show-case button {
  padding: 0;
}
.blogTilesBg .button-name button {
  width: 250px;
  height: 49px;
}
.blogTilesBg .fivecardswp .rightGrid .SmallCard .doublebr {
  font-size: 16px;
  height: 48px;
  line-height: 26px;
}
.blogTilesBg .fivecardswp .rightGrid .SmallCard p.doublebr {
  display: none;
}
.blogTilesBg .fivecardswp .rightGrid .Boxdivcontent div,
.fivecardswp .rightGrid .Boxdivcontent p {
  font-size: 12px;
}
.blogTilesBg .fivecardswp .rightGrid .Boxdivcontent div:first-child {
  -webkit-line-clamp: 1 !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  word-wrap: break-word !important;
}
.blogTilesBg .fivecardswp .leftGrid .doublebr:first-child {
  font-size: 24px;
  height: 40px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.blogTilesBg .fivecardswp .rightGrid .Boxdivcontent p .dotpoint {
  display: inline !important;
}

.blogTilesBg .doublebr {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.blogTilesBg .fiveline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}
.blogTilesBg .threeline {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
@media only screen and (min-width: 1024px) {
  .blogTilesBg .fivecardswp .rightGrid .imgheight {
    height: 167px;
  }
  .blogTilesBg .fivecardswp .leftGrid .imgheight {
    height: 420px;
  }
}
@media only screen and (min-width: 600px) {
  .blogTilesBg .button-name button {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .blogTilesBg .fivecardswp .leftGrid .doublebr:first-child {
    font-size: 16px;
    height: 48px;
    line-height: 26px;
  }
}
