.payment-detail-section {
  padding: 6px 24px;
  border-radius: 3px;
  margin-bottom: 24px;
}

.shipping-address-wrapper-left .bottom-button-text-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 20px;
}

.shipping-address-wrapper-left .bottom-button-text-wrapper .center-align-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .shipping-address-wrapper-left .bottom-button-text-wrapper {
    flex-direction: column;
  }
}
