/* ===================================== Poppins Font Start =========================================== */
@font-face {
  font-family: "Poppins-Regular";
  src: url(./Poppins/Poppins-Regular.woff2) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url(./Poppins/Poppins-Medium.woff2) format("truetype");
  font-display: swap;
}

/* ===================================== Gotham Font Start =========================================== */
@font-face {
  font-family: "Gotham-Regular";
  src: url(./Gotham/Gotham-Regular.woff2) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Gotham-Medium";
  src: url(./Gotham/Gotham-Book.woff2) format("truetype");
  font-display: swap;
}

/* ===================================== HelveticaNeue Font Start =========================================== */
@font-face {
  font-family: "HelveticaNeue";
  src: url(./HelveticaNeue/HelveticaNeue-Condensed-Bold.woff2) format("truetype");
  font-display: swap;
}

/* ===================================== Arial Font Start =========================================== */
@font-face {
  font-family: "Arial";
  src: url(./Arial/arial.woff2) format("truetype");
  font-display: swap;
}

/* ===================================== HCLTechRoobert Font Start =========================================== */
@font-face {
  font-family: "HCLTechRoobert";
  src: url(./HCLTechRoobert/HCLTechRoobert-Regular.woff2) format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "HCLTechRoobert";
  src: url(./HCLTechRoobert/HCLTechRoobert-Medium.woff2) format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "HCLTechRoobert";
  src: url(./HCLTechRoobert/HCLTechRoobert-SemiBold.woff2) format("truetype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "HCLTechRoobert";
  src: url(./HCLTechRoobert/HCLTechRoobert-Bold.woff2) format("truetype");
  font-weight: 700;
  font-display: swap;
}

/* ===================================== Inter Font Start =========================================== */

@font-face {
  font-family: "Inter";
  src: url(./Inter/Inter-Regular.woff2) format("woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url(./Inter/Inter-Medium.woff2) format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url(./Inter/Inter-SemiBold.woff2) format("woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url(./Inter/Inter-Bold.woff2) format("woff2");
  font-weight: 700;
  font-display: swap;
}
