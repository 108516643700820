.address-section .shipping-form-control {
  width: 100%;
  float: left;
}

.address-section .bottom-button-text-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 20px;
}

.address-section .text-wrapper,
.address-section .select-wrapper {
  padding-right: 15px;
  /* padding-bottom: 20px; */
}

.address-section .bottom-button-text-wrapper .center-align-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 1023px) {
  .address-section .text-wrapper {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) {
  .address-section .bottom-button-text-wrapper {
    flex-direction: column;
  }
}
