.autocompleteMultiSelectPopper {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
    rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
.autocompleteMultiSelectPopper .autocompleteMultiSelectPapper ul {
  padding: 10px;
  max-height: 55vh;
  min-height: 16px;
}
.autocompleteMultiSelectPopper .autocompleteMultiSelectPapper li {
  padding: 0;
}
.autocompleteMultiSelectInputRoot {
  flex-wrap: wrap;
  padding: 9px 30px 9px 9px;
  overflow-y: auto;
  overflow-x: hidden;
}
.autocompleteMultiSelectInputRoot input {
  display: flex;
  padding: 0px;
}
.autocompleteMultiSelectInputRoot .autocompleteMultiSelectTag {
  margin: 3px;
}
.autocompleteMultiSelectroot label {
  top: -4px;
}
.autocompleteMultiSelectroot .Mui-focused {
  top: 0;
}
.autocompleteMultiSelectroot .Platform-x-FormControl-root {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.23);
}
.autocompleteMultiSelectroot.Mui-focused .Platform-x-FormControl-root {
  border-color: #2d2d39;
}
.autocompleteMultiSelectInputRoot .Platform-x-OutlinedInput-notchedOutline {
  border: none;
}
