.listbox .doticon {
  position: relative;
  margin-left: 10px;
  order: 2;
  min-width: auto;
  padding-left: 14px;
}

.listbox .doticon:after {
  content: ".";
  position: absolute;
  left: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #2d2d39;
}

.listbox .doticonmobile {
  position: relative;
  display: none;
  order: inherit;
  margin-left: 10px;
  padding-left: 14px;
}

.listbox .doticonmobile:after {
  content: ".";
  position: absolute;
  left: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #2d2d39;
}

.listbox {
  transition: all 0.3s;
  align-items: center;
  padding: 10px 15px;
  display: flex;
  min-height: 72px;
  background: #ffffff;
  border: 1px solid #d9dbe9;
  border-radius: 4px;
  margin-bottom: 14px;
  cursor: pointer;
}

.listbox .rightspace {
  padding-right: 10px;
}

.listbox:hover {
  box-shadow: 0px 9px 21px rgba(0, 0, 0, 0.07);
  transition: all 0.3s;
  cursor: pointer;
  border-color: #14142b;
}

.listbox:hover .hoverIcon img {
  filter: brightness(0) saturate(100%) invert(8%) sepia(6%) saturate(6495%) hue-rotate(204deg)
    brightness(93%) contrast(97%);
}

.listbox .iconsmob {
  margin: 10px;
}

.listbox .icons {
  padding: 0;
  display: flex;
  margin-right: 5px;
}

.listbox .icons:hover {
  background: transparent;
}

.listbox:hover .icons {
  color: #2d2d39;
}

.listbox .img {
  min-width: 44px;
  min-height: 44px;
  max-width: 44px;
  max-height: 44px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 20px;
  display: flex;
}

.usergridview .img {
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 14px;
}

.listbox .datetimeweb {
  /* display: inline-flex; */
  padding: 0 15px;
  min-width: 180px;
  margin-right: 20px;
  min-height: 40px;
  align-items: center;
  border-left: 1px solid #ced3d9;
  border-right: 1px solid #ced3d9;
  max-width: 185px;
  justify-content: center;
}

.listbox .statusweb {
  display: inline-flex;
  padding: 0 15px;
  min-width: 125px;
  min-height: 40px;
  align-items: center;
  border-left: 1px solid #ced3d9;
  max-width: 170px;
  justify-content: center;
  text-align: center;
  gap: 3px;
}

.listbox .statusText {
  padding: 5px 9px;
  text-transform: capitalize;
  border-radius: 3px;
  font-weight: 500;
}

.listbox .mobstatusIcon {
  border-radius: 3px;
  display: none;
  margin-left: 5px;
  /* width: 24px; */
  height: 24px;
  align-items: center;
  justify-content: center;
}

.listbox .mobstatusIcon img {
  width: 15px;
}

.usergridview {
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.usergridview .greendot {
  width: 8px;
  height: 8px;
  margin-right: 5px;
  background: #5cb85b;
  border-radius: 50%;
  display: inline-block;
}

.usergridview .gridbox {
  padding: 12.7px;
  display: flex;
  min-height: 72px;
  background: #ffffff;
  border: 1px solid #ced3d9;
  border-radius: 4px;
  align-items: flex-start;
  justify-content: space-between;
}

.horizThreeDotIcon,
.vertThreeDotIcon {
  vertical-align: middle;
  transition: transform 0.2s;
  cursor: pointer;
}

.listbox .horizThreeDotIcon {
  display: flex;
}

.viewallctamob {
  padding: 10px 5px !important;
}

.listbox .datetimemob {
  display: none;
  color: #89909a;
}

.listbox .datetimemob div:first-child {
  margin-right: 10px;
  position: relative;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listbox .datetimemob div:last-child {
  position: relative;
}

.listbox .datetimemob div:last-child:after {
  border-right: 1px solid #89909a;
  position: absolute;
  content: "";
  left: -5px;
  top: 4px;
  height: 10px;
  width: 1px;
}

@media (max-width: 1023px) {
  .listbox .mobstatusIcon {
    display: inline-flex;
  }

  .listbox .datetimemob {
    display: flex;
  }

  .listbox .doticon:after {
    display: none;
  }

  .listbox {
    padding: 5px 10px;
  }

  .listbox .img {
    margin-right: 10px;
  }

  .listbox .icons {
    display: none;
    margin-right: 5px;
  }

  .listbox .iconsmob {
    display: flex;
    margin: 10px 0;
  }

  .listbox .doticonmobile {
    display: flex;
    order: 3;
    padding-left: 10px;
  }

  .listbox .doticon {
    position: relative;
    margin-left: 0;
    order: 1;
    min-width: 100%;
    padding-left: 0;
  }

  .listbox .datetimeweb {
    display: none;
  }

  .listbox .statusweb {
    display: none;
  }

  .listbox .boxWorkflowStatus {
    min-width: 160px;
  }

  .listbox .horizThreeDotIcon {
    display: none;
  }

  .listbox .vertThreeDotIcon {
    display: flex;
  }

  .userSearchBox {
    width: 185px;
  }

  .viewallctamob {
    padding: 0 !important;
  }

  .viewallctamob img {
    transform: rotate(90deg);
  }
}

@media (max-width: 700px) {
  .listbox .datetimemob div:first-child {
    max-width: 90px;
  }

  .listbox .rightspace {
    padding-right: 0px;
  }
}

@media (max-width: 480px) {
  .listbox .datetimemob div:first-child {
    max-width: 68px;
  }
}
