.firstButtonWrapper {
  left: 50%;
  transform: translateX(-50%);
  transition: transform 0.3s ease-in-out;
}

.serviceCardItems:hover .firstButtonWrapper {
  transform: translateX(-50%) translateY(-120px);
}
.serviceCardItems:hover .cardGradient {
  display: block !important;
}
