.damcontent_container {
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1500;
  overflow-y: hidden;
}

.damcontent_container .right-topbar-container .right-topbar {
  padding: 14px 0px 10px 12px;
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.Platform-x-ButtonBase-root.tagbtn {
  padding: 6px 12px;
  min-width: 79px;
  font-size: 14px;
  font-weight: 400;
  margin-right: 10px;
  margin-bottom: 9px;
}
