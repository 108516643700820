.order-confirmation-wrapper .modal-close {
  position: relative;
}
.order-confirmation-wrapper .modal-close .close-icon {
  position: absolute;
  top: 0px;
  right: -50px;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .order-confirmation-wrapper .Platform-x-Dialog-container .Platform-x-Paper-root {
    bottom: auto;
  }
}
