.rightsidebar-cart-drawer {
  height: calc(100% - 180px) !important;
  overflow: scroll;
  padding: 20px;
}

.bottom-action-button-cart {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.bottom-action-button-cart .subtotal-text {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 20px 0px;
  margin-bottom: 16px;
}

.bottom-action-button-cart .black-checkout-button,
.bottom-action-button-cart .white-viewcart-button {
  min-width: 100%;
  width: 100%;
  height: 50px;
}

.bottom-action-button-cart .white-viewcart-button {
  margin-bottom: 15px;
}

.rightsidebar-cart-drawer .close-right-panel {
  float: right;
  display: none;
}

@media screen and (max-width: 767px) {
  .rightsidebar-cart-drawer {
    width: 100vw !important;
  }
  .rightsidebar-cart-drawer .close-right-panel {
    display: block;
    cursor: pointer;
  }
}
