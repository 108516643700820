.product-detail-popup .popup-image-container {
  height: 100vh;
}
.product-detail-popup .popup-close {
  position: absolute;
  right: 12px;
  top: 12px;
  display: flex;
  z-index: 99;
}
.product-detail-popup .prev-button svg,
.product-detail-popup .next-button svg {
  font-size: 40px;
}
