.casecropsbox .boxwp {
  padding: 5px;
  height: 300px;
  max-width: 100%;
}

.casecropsbox .boxwp .imgbox {
  border-radius: 5px;
  overflow: hidden;
  height: 100%;
  position: relative;
}

.casecropsbox .boxwp .imgbox img {
  height: 100%;
  object-fit: cover;
}

.casecropsbox .boxwp .imgbox h6 {
  padding: 5px 6px;
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  border-top-right-radius: 10px;
  background: -moz-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0.66) 74%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0.66) 74%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0.66) 74%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#a8ffffff', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

.editIconfixed {
  position: absolute !important;
  right: 60px;
  top: 10px;
  width: 40px;
  height: 40px;
  border: 1px solid #000 !important;
  display: flex !important;
  padding: 0 !important;
  margin: 0 !important;
  max-width: 40px;
  min-width: 40px !important;
}

.editIconfixed span {
  width: 40px;
  height: 40px;
  padding: 0 !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeBtnIcon {
  position: absolute !important;
  right: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
  border: 1px solid #000 !important;
  display: flex !important;
  padding: 0 !important;
  margin: 0 !important;
  max-width: 40px;
  min-width: 40px !important;
  border-radius: 4px !important;
}

.closeBtnIcon span {
  width: 40px;
  height: 40px;
  padding: 0 !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottomDoneBtn {
  bottom: 0;
  left: 0;
  padding: "10px 32px 40px";
  width: "100%";
  background-image: "linear-gradient(to bottom; rgba(255; 255; 255; 0) 8%; #fff)";
}

.wholecontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 1023px) and (min-width: 768px) {
  .casecropsbox .boxwp {
    height: 280px;
  }
}

@media (max-width: 767px) {
  .casecropsbox .boxwp .imgbox h5 {
    padding: 5px 0;
  }

  .casecropsbox .boxwp {
    height: 150px;
  }

  .casecropsbox {
    max-width: 100%;
    padding: 10px;
    border: 0;
    box-shadow: none;
  }
}
