.service-card7 .service-card2 .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.service-card7 .service-card2 .slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
.service-card7 .service-card2 .slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0;
}
.service-card7 .service-card2 .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.service-card7 .service-card2 .slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}
.service-card7 .service-card2 .slick-initialized .slick-slide {
  display: block;
}
.service-card7 .service-card2 .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.service-card7 .service-card2 .slick-prev,
.service-card7 .service-card2 .slick-next {
  display: none !important;
}
.service-card7 .service-card2 .slick-track {
  display: flex !important;
  margin-left: -10px;
  margin-right: -10px;
}

.service-card7 .service-card2 .slick-slide {
  height: inherit !important;
}
.service-card7 .service-card2 .slick-slide > div {
  height: 100% !important;
}

.service-card7 .service-card2 .slick-slide {
  margin: 0 10px;
}
.service-card7 .service-card2 .slick-dots {
  position: absolute;
  display: flex !important;
  padding: 0;
  margin: 38px 0 0;
  cursor: pointer;
}

.service-card7 .service-card2 .slick-dots li {
  display: flex;
  width: 41px;
  height: 3px;
  background: #ced3d9;
  margin-right: 5px;
}
.service-card7 .service-card2 .slick-dots li.slick-active button {
  background: #2d2d39;
  height: 3px;
}
.service-card7 .service-card2 .slick-dots li button {
  width: 100%;
  height: 100%;
  background: #ced3d9;
  overflow: hidden;
  border-color: transparent;
  cursor: pointer;
  padding: 0;
}
.service-card7 .service-card2 .slick-dots li button:before {
  display: none;
}
@media (max-width: 991px) {
  .service-card7 .service-card2 .slick-dots {
    position: relative;
    margin: 0px 0 45px 0;
  }
  .service-card7 .service-card2 .slick-dots li,
  .service-card7 .service-card2 .slick-dots li button,
  .service-card7 .service-card2 .slick-dots li.slick-active button {
    width: 18px;
    height: 3px;
  }
}
/* ===== Scrollbar CSS ===== */
/* width */
.service-card7 .service-card2::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.service-card7 .service-card2::-webkit-scrollbar-track {
  background: transparent;
  box-shadow: inset 0 0 5px #dddddd;
  border-radius: 4px;
  border-left: 1.5px solid transparent;
  border-right: 1.5px solid transparent;
}
/* Handle */
.service-card7 .service-card2::-webkit-scrollbar-thumb {
  background: #b1b1b1;
  border-radius: 4px;
}
.fourbr {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.singlebr {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.text-truncated-4line {
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  min-height: 96px;
}
.text-truncated-2line {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  min-height: 60px;
}
